import React from "react";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import Slide from 'react-reveal/Slide';


export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>Oberlin Photo Stories</title>
            <meta name="title" content="Oberlin Photo Stories: Oberlin College through a photographer‘s lens" />
            <meta name="description" content="Oberlin College’s photo stories offer a glimpse into classrooms, labs, libraries, concert halls, campus life, and more." />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://photo-stories.oberlin.edu/" />
            <meta property="og:title" content="Oberlin Photo Stories: Oberlin College through a photographer’s lens" />
            <meta property="og:description" content="Oberlin College’s photo stories offer a glimpse into classrooms, labs, libraries, concert halls, campus life, and more." />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/og.jpg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="Student studying together in Mudd Library." />
            <meta property="fb:app_id" content="1571317633107125" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://photo-stories.oberlin.edu/" />
            <meta property="twitter:title" content="Oberlin Photo Stories: Oberlin College through a photographer’s lens" />
            <meta property="twitter:description" content="Oberlin College’s photo stories offer a glimpse into classrooms, labs, libraries, concert halls, campus life, and more." />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/og.jpg" />
            <meta property="twitter:image:alt" content="Student studying together in Mudd Library." />
        </Helmet>
        <main>
          <div className="container my-5 toc">
              <div className="row pola-group">
              {data.allAirtable.nodes.map(node => (
                  <Slide bottom>
                  <div className="col-lg-4 col-8 mx-auto mb-3 mb-lg-5">
                      <a href={node.data.slug}>
                      <div className="card polaroid">
                          <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                          <div className="card-body text-center">
                              <p className="card-text pola-caption">{node.data.story}</p>
                          </div>
                      </div>
                      </a>
                  </div>
                  </Slide>
              ))}
              </div>
          </div>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
  allAirtable(filter: {table: {eq: "data"}, data: {cover: {eq: true}, publish: {eq: true}}}, sort: {fields: data___published_date, order: DESC}) {
        nodes {
          data {
            alt
            file {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 300, cropFocus: ATTENTION, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            story
            slug
          }
        }
      }
}  
`;